@import './utils.scss';

// navigation css
.navs{
    display: flex;
    align-items: center;
}
.nav-items{
    list-style-type: none;
    font-family: 'Segoe UI';
    font-weight:500;
    font-size: large;
    margin-right: 1.5rem;
    position: relative;
    &::before{
        position: absolute;
        content: '';
        background-color:$primaryColor;
        height: 3px;
        width: 0%;
        bottom: -10px;
        left: 0;
        transition: all 0.2s linear;
    }
}
.nav-items.active{
    color:$primaryColor;
    &::before{
        position: absolute;
        content: '';
        background-color:$primaryColor;
        height: 3px;
        width: 60%;
        bottom: -10px;
        left: 20%;
        transition: all 0.2s linear;
    }
}
.nav-items:hover{
    color: $primaryColor;
}
.nav-items:hover::before{
    position: absolute;
    content: '';
    background-color:$primaryColor;
    height: 3px;
    width: 100%;
    bottom: -10px;
    left: 0;
    transition: all 0.2s linear;
}
.navbar{
    background: transparent;
    position: sticky;
    top: 0;
    z-index: 999;
    transition: all 0.3s ease-in;
}
.navbar.active{
    background: white;
    box-shadow: 1px 2px 4px 1px rgba($color: #000000, $alpha: 0.09);
    transition: all 0.3s ease-in; 
}
.bars{
    display: none;
    font-size: x-large;
}
@media screen and (max-width:780px) {
  
.nav-items.active{
  color:$primaryColor;
  &::before{
      position: absolute;
      content: '';
      background-color:$primaryColor;
      height: 3px;
      width: 100%;
      bottom: -10px;
      left: 0;
      transition: all 0.2s linear;
  }
}
    .bars{
        display: block;
    }
    .navs{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        background: white;
        box-shadow: 1px 8px 6px 2px rgba($color: #000000, $alpha: 0.09);
        width: 80%;
        padding-left: 20px;
        padding-right: 20px;
        height: 100vh;
        left: -150%;
        transform: rotate(40deg);
        transition: all 0.4s ease-in-out;
    }
    .navs.active{
        left: 0%;
        transform: rotate(0deg);
        transition: all 0.4s ease-in-out;
    }
    .nav-items{
        margin-top: 5px;
        width: 100%;
        padding: 8px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.09);
    }
}

.nav-items:hover{
  
}
.backDrop_bg{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}


// art and snake image gallery 
.gallery{
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-width:33%;
    -moz-column-width: 33%;
    column-width: 33%;
  }
  .gallery .pics{
    -webkit-transition:  all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    &:hover{
      filter: opacity(0.8);
    }
  }
  
  @media (max-width:991px) {
    .gallery{
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
  }
  @media (max-width:740px) {
    .gallery{
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }
  
  // gallery modal 
  .modal{
    width:100%;
    height:100%;
    object-fit: contain;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition:opacity 0.4s ease, visibility 0.4s ease, transfrom 0.4s ease;
    visibility:hidden;
    opacity: 0;
    transform:scale(0);
    overflow:hidden;
    z-index:999;
  }
  .open{
    visibility: visible;
    opacity: 1;
    transform:scale(1);
    width: 100%;
    overflow: auto;
  }
  .modal img{
    width: auto;
    max-width: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
  }
  .slider{
    height: 80vh;
  }
  @media (max-width: 768px){

    .slider{
      height: 100%;
    }
    
    }
